<template>
    <div>
      <b-row>
        <b-col lg="8">
          <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-tree-info') }}</p>
          <p>{{ $t('feature-tree-info-content') }}</p>
          <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-tree-info-title-1') }}</p>
          <p>{{ $t('feature-tree-info-title-1-content') }}</p>
          <p>{{ $t('feature-tree-info-title-1-content-2') }}</p>
          <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-tree-info-title-2') }}</p>
          <p>{{ $t('feature-tree-info-title-2-content') }}</p>
          <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-tree-info-title-3') }}</p>
          <p>{{ $t('feature-tree-info-title-3-content') }}</p>
          <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-tree-info-title-4') }}</p>
          <p>{{ $t('feature-tree-info-title-4-content') }}</p>
          <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-tree-info-title-5') }}</p>
          <p>{{ $t('feature-tree-info-title-5-content') }}</p>
          <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-tree-info-title-6') }}</p>
          <p>{{ $t('feature-tree-info-title-6-content') }}</p>
        </b-col>
        <b-col lg="4">
          <RightMenu />
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import RightMenu from "@/layout/RightMenu";
  export default {
    components: {
      RightMenu,
    },
  };
  </script>
  
  <style scoped>
  .font-64 {
    font-size: 64px;
  }
  .font-24 {
    font-size: 24px;
  }
  .title-block {
    font-size: 12px;
    font-weight: 300;
    color: #636466;
    width: 75%;
  }
  .title-secondary {
    font-size: 16px;
    font-weight: 300;
    color: #636466;
    width: 75%;
    margin-bottom: 40px !important;
  }
  .content-slogan {
    color: #c71313;
    font-size: 48px !important;
    width: 50%;
    margin: 16px 0 !important;
  }
  .content-card {
    border: 1px solid #e6e8ec;
    border-radius: 24px;
    overflow: hidden;
    transition: 500ms;
    cursor: pointer;
  }
  .content-card p {
    height: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .content-card:hover {
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  }
  </style>
  